import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, PLATFORM_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatListModule as MatListModule } from '@angular/material/list';
import {
  HttpClientJsonpModule,
  HttpClientModule,
  HttpClient
} from '@angular/common/http';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MatCardModule as MatCardModule } from '@angular/material/card';
import { MatFormFieldModule as MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule as MatInputModule } from '@angular/material/input';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OTPDeviceList } from './otpSession+UI/deviceList/otpDeviceList.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OTPWaitingForInput } from './otpSession+UI/otpWaitingForInput.component';
import { UsernameFormSessionComponent } from './usernameForm/usernameFormSession.component';
import { PasswordFormSessionComponent } from './passwordForm/passwordFormSession.component';
import { AuthenticatedUser } from './otpSession+UI/authenticated-user.component';

import { CDKCoreModule } from '@rogers/cdk/core';
import { FormModule as CoreFormModule } from '@rogers/cdk/form';
import { PictureModule } from '@rogers/cdk/picture';
import { TileComponent } from '@rogers/cdk/tile';
import { LinkComponent } from '@rogers/cdk/link';
import { IconComponent } from '@rogers/cdk/icon';
import { ModalModule } from '@rogers/cdk/modal';
import { ButtonComponent } from '@rogers/cdk/button';
import { PopoverModule } from '@rogers/cdk/popover';
import { AlertComponent } from '@rogers/cdk/alert';
import { AccordionModule } from '@rogers/cdk/accordion';
import { CookieService } from 'ngx-cookie-service';

import {
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { analyticsServiceFactory } from './shared/analytics/analytics.service.factory';
import { AnalyticsService, PendingPayloadsSsrService } from '@rogers/analytics';
import { AnalyticsModule } from '@rogers/self-serve-analytics';

import { ConfigurationService } from './shared/configuration.service';
import { environmentNameResolver } from './shared/environment-name-resolver';
import { WINDOW } from './shared/window.token';
import { environment } from '../environments/environment';
import { of } from 'rxjs';
import { RecaptchaModule, RECAPTCHA_LANGUAGE } from '@rogers/ng-recaptcha';
import { GlobalStateService } from './shared/global-state.service';
import { CounterDirective } from './shared/counter.directive';
import { SetPasswordFormComponent } from './set-password-form/set-password-form.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { ScssLoaderService } from './shared/scss.loader.service';
import { CommonService } from './shared/common.service';
import { LivechatComponent } from './livechat/livechat.component';
import { LiveChatService } from './livechat/livechat.service';
import { LoaderComponent } from './shared/loader/loader.component';
import { ChangeUsernameComponent } from './change-username/change-username.component';
import { ConfirmChangeUsernameComponent } from './change-username/confirm-change-username/confirm-change-username.component';
import { OtpConfirmationComponent } from './change-username/otp-confirmation/otp-confirmation.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfirmPasswordComponent } from './change-password/confirm-password/confirm-password.component';
import { ChangeRecoveryNumberComponent } from './change-recovery-number/change-recovery-number.component';
import { ConfirmRecoveryNumberComponent } from './change-recovery-number/confirm-recovery-number/confirm-recovery-number.component';
import { RecoveryNumberOtpConfirmationComponent } from './change-recovery-number/recovery-number-otp-confirmation/recovery-number-otp-confirmation.component';
import { provideSelfServeAnalyticsService } from './shared/analytics/analytics-service-proxy.service';
import { provideAnalyticsConfigurationUsingClass } from './shared/analytics/analytics-configuration.service';
import { EasAnalyticsConfiguration } from './shared/analytics/analytics-mapping-config';
import { Router } from '@angular/router';
import { AccountSelectionComponent } from './account-selection/account-selection.component';
import { RegisteFormSessionComponent } from './register/register.component';
import { SetMultiFactorAuthComponent } from './set-multi-factor-auth/set-multi-factor-auth.component';
import { SecurityQuestionComponent } from './security-question/security-question.component';
import { MultiFactorAuthSuccessComponent } from './multi-factor-auth-success/multi-factor-auth-success.component';
import { UpdateMultiFactorAuthComponent } from './update-multi-factor-auth/update-multi-factor-auth.component';
import { UsernameFormNoRecaptchaComponent } from './username-form-no-recaptcha/username-form-no-recaptcha.component';
import { NgxTsRiskidModule, RISKID_SDK_CONFIG, RiskidSdkConfig } from '@transmitsecurity/ngx-ts-riskid';
import { UserSessionRiskService } from './shared/user-session-risk-service';
import { DRSConfigurationService } from './shared/drs-setting-service';
import { IgniteTvAccountSelectionComponent } from './ignite-tv-account-selection/ignite-tv-account-selection.component';

export function setReCaptchaLanguage(globalStateService: GlobalStateService) {
  return globalStateService.getCurrentLangBehaviorSubject();
}


@NgModule({
    declarations: [
        AppComponent,
        OTPDeviceList,
        OTPWaitingForInput,
        UsernameFormSessionComponent,
        PasswordFormSessionComponent,
        AuthenticatedUser,
        CounterDirective,
        SetPasswordFormComponent,
        LivechatComponent,
        LoaderComponent,
        ChangeUsernameComponent,
        ConfirmChangeUsernameComponent,
        OtpConfirmationComponent,
        ChangePasswordComponent,
        ConfirmPasswordComponent,
        ChangeRecoveryNumberComponent,
        ConfirmRecoveryNumberComponent,
        RecoveryNumberOtpConfirmationComponent,
        AccountSelectionComponent,
        RegisteFormSessionComponent,
        SetMultiFactorAuthComponent,
        SecurityQuestionComponent,
        MultiFactorAuthSuccessComponent,
        UpdateMultiFactorAuthComponent,
        UsernameFormNoRecaptchaComponent,
        IgniteTvAccountSelectionComponent
    ],
    imports: [
        BrowserModule,
        CDKCoreModule.forRoot({ }),
        CoreFormModule,
        ButtonComponent,
        PictureModule,
        TileComponent,
        ModalModule,
        LinkComponent,
        IconComponent,
        PopoverModule,
        AlertComponent,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatListModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NgxTsRiskidModule,
        RecaptchaModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            }
        }),
        AccordionModule,
        AnalyticsModule
    ],
    providers: [
        {
            provide: 'environment',
            useValue: environment,
        },
        { provide: WINDOW, useValue: window },
        {
            provide: APP_INITIALIZER,
            useFactory: ConfigurationServiceFactory,
            deps: [ConfigurationService],
            multi: true,
        },
        {
            provide: RECAPTCHA_LANGUAGE,
            useFactory: setReCaptchaLanguage,
            deps: [GlobalStateService]
        },
        {
            provide: AnalyticsService,
            useFactory: analyticsServiceFactory,
            deps: [
                PendingPayloadsSsrService,
                Router,
                GlobalStateService,
                CookieService,
                PLATFORM_ID
            ]
        },
        provideAnalyticsConfigurationUsingClass(EasAnalyticsConfiguration),
        provideSelfServeAnalyticsService(),
        ConfigurationService,
        BnNgIdleService,
        ScssLoaderService,
        CommonService,
        LiveChatService,
        CookieService,
        UserSessionRiskService,
        DRSConfigurationService,
        { provide: RISKID_SDK_CONFIG,
          deps: [DRSConfigurationService],
          useFactory: (configService) => configService.getValues()
      }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './idp/__default/assets/i18n/', '.json?11082024');
}

export function ConfigurationServiceFactory(
  configService: ConfigurationService
) {
  const env = environmentNameResolver(location.href);
  const url = `./idp/__default/assets/config/ui-config.${env}.json?t=${environment.buildTimestamp}`;
  return () => configService.loadConfigurationData(url);
}
